
import { useState } from "react"
import { ThemeProvider } from "styled-components"
import AppData from "../../../appData"
import { Button } from "../../../components/button/button"
import { DataGrid } from "../../../components/dataGrid/dataGrid"
import DialogBox from "../../../components/dialogBox/dialogBox"
import { Menu } from "../../../components/menu/menu"
import PopUpForm from "../../../components/popupForm/popupForm"
import Icons from "../../../icons/icons"
import PrimaryTheme from "../../../Themes/primaryTheme"
import { CreateCustomer, SummaryView, copyUserConfig, generateStates, getBlockDiagramData, initializeConfig, removeUserConfig, syncConfig, updateUserConfig } from "./configurationEvents"
import { freezeConfig, getBlockFunctions, getDeviceProperties, getFunctionState } from "../blockDiagramScreen/blockDiagramEvents"
import Message from "../../../components/messages/messages"
import LoadingSpinner from "../../../components/loadingSpinner/loadingSpinner"
import PopupPdf from "../../../components/popupForm/popupPdf"
import configJson from "../../../config.json"


interface PropsIF {
	data: any[]
	Columns: any[]
	shape?: string
	fill?: string
	stroke?: string
	onSync: any
	row?: any
	recentData: any
	Error?: any
	configData: any
	onSelectRow: (row: any) => void
	onSubmitEdit: (formData: { id: string, value: string }[]) => void
	onSubmitDuplicate: (formData: { id: string, value: string }[]) => void
	onClickDelete: () => void
	onclickConfig: (active: boolean) => void
	handleFreeze: any
	handleEdit: any
	handleCopy: any
	handleFreezeSubmit: any
	OnclickViewAddendum?: any
	onClickDownLoadConfig?: any
	pdfhandle?: any
	hide?: any
	onSelect: any
	onSelectSetMessage?: any
}

function convertUTCDateToLocalDate(utcDate: any) {
	var newDate = new Date(utcDate);

	return newDate;
}

function ConfigLocalTable({ onSelectSetMessage,onSelect, data, shape = 'normal', handleEdit, hide, pdfhandle, OnclickViewAddendum, handleCopy, fill = 'normal', Error, stroke = 'normal', Columns, recentData, configData, handleFreezeSubmit, handleFreeze, onSync, row, onSelectRow, onSubmitDuplicate, onSubmitEdit, onClickDelete, onclickConfig,onClickDownLoadConfig }: PropsIF) {
	const [show, setShow] = useState(false);
	const [selectedRow, setSelectedrow] = useState<any>();
	const [syncSelect, setSyncSelect] = useState(false);
	const [viewAddendum, setViewAddendum] = useState(false);
	const [active, setactive] = useState(false)
	const [copy, setCopy] = useState(false)
	const [edit, setEdit] = useState(false)
	const [wait, setWait] = useState(false)
	const [message, setMessage] = useState()
	const [visible, setVisible] = useState(false)
	const [loader, setloader] = useState(false)
	const [html, setHtml] = useState<any>()
	const [pdf, setpdf] = useState(pdfhandle)
	const [download, setDownload] = useState(false)
	const setMessageComp = (visible: boolean, message: any) => {
		return setMessage(message), setVisible(visible)
	}
	var formData = require('./editconfig.json')
	const handler = () => {
		setShow(!show);
	}
	const lostfocus = () => {
		setShow(false);
	}
	var localdata: any[] = []
	var remotedata: any[] = []
	var platform: any[] = []
	var name: any[] = []
	var version: any[] = []
	var variant: any[] = []
	var modified_date: any[] = []
	var id: any = []
	data.forEach((value: any) => { return platform.push(value['platform']) })
	data.forEach((value: any) => { return name.push(value['name']) })
	data.forEach((value: any) => { return version.push(value['version']) })
	data.forEach((value: any) => { return modified_date.push(value['modified_date']) })
	data.forEach((value: any) => { return variant.push(value['variant']) })
	localdata.push(name, platform, modified_date)
	console.log("log", data)
	async function downloadAddendum() {
		//Search configuration steps
		setDownload(true)
		setloader(true)
		try {
			await generateStates(AppData.server)
			await AppData.localServer.setResponseTypeHeader("blob");
			AppData.api.pdfGeneratorFile.bodyParams = { header: true, footer: true }
			const response = await AppData.localServer.sendCommandReceiveAllData(AppData.api.pdfGeneratorFile, "Downloading PDF from local server failed.", 1)
			const contentDisposition =
			response.headers['content-disposition'];

			console.log('contentDisposition: ', contentDisposition);

			// 2) set the fileName variable to the default value
			let fileName = "Temp.pdf";

			// 3) if the header is set, extract the filename
			if (contentDisposition) {
				const fileNameMatch =
				contentDisposition.match(/filename="(.+)"/);
				console.log('fileNameMatch', fileNameMatch);
				if (fileNameMatch.length === 2) {
					fileName = fileNameMatch[1];
				}
			}	
			// create "a" HTML element with href to file & click
			const anchorElement = document.createElement('a');
			// create file link in browser's memory
			var blobObj = new Blob([response.data], { type: "application/pdf" });
			const href = URL.createObjectURL(blobObj);	
      		anchorElement.href = href;

			anchorElement.download = fileName;

			document.body.appendChild(anchorElement);
			anchorElement.click();
	
			document.body.removeChild(anchorElement);
			window.URL.revokeObjectURL(href);

			await AppData.localServer.setResponseTypeHeader("json");
			setloader(false)
			setDownload(false)
		}
		catch {
			await AppData.localServer.setResponseTypeHeader("json");
			setloader(false)
			setDownload(false)
		}
	}

	//Buttons present in configuration table  
	const col1 = (coldata: any, rowindex: any) => {
		console.log('rooo', coldata)
		return (coldata.map((row: any, rowindex: any) => (((row.frozen) || (row.custom_part_number)) ? <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "end" }}>
			<div style={{ width: '19vw', display: 'flex', position: 'relative', alignItems: 'center', justifyContent: 'end', gap: '4px' }}>
				<Button onClick={() => { setVisible(false); setViewAddendum(true) }} shape="ViewAddendum" fill="transparent" spacing="ViewAddendum" stroke="sync" color="sync" >View Addendum</Button>
				{(configJson.WEB_ONLY===0)&&<Button onClick={() => { setSyncSelect(true) }} spacing='none' shape='localButton' stroke='sync' color='sync' fill='transparent' disabled={(navigator.onLine) ? false : true}>Sync</Button>}
				<div style={{ width: '30px', height: '31px', border: '1px solid #3399D5', display: 'flex', justifyContent: 'center', alignItems: 'center',cursor:"pointer"}} onClick={handler}>
					<Icons stroke='menu' iconName={'more-vertical'} size={'15px'} />
				</div>
			</div>
			<div onMouseLeave={lostfocus}>
				{show && selectedRow === rowindex && (
					<Menu hide={setShow} spacing='local' shape='local' fontSize='local' effects='outerShadow' fill='local' data={(navigator.onLine) ?(configJson.WEB_ONLY===0)? [
						{ name: 'Sync to remote', icon: 'user', tooltip: 'sync configuration ', onclick: () => { setSyncSelect(true) } },
						{ name: 'View Configuration', icon: 'settings', tooltip: 'view current configuration ', onclick: () => { activeRow(rowindex, true) } },
						{ name: 'Download Config', icon: 'settings', tooltip: 'Download current configuration ', onclick: () => { return onClickDownLoadConfig() } },
						{ name: 'Duplicate', icon: "file", tooltip: 'duplicate configuration', onclick: () => { return handleCopy() } },] : [{ name: 'View Configuration', icon: 'settings', tooltip: 'view current configuration ', onclick: () => { activeRow(rowindex, true) } },
						{ name: 'Download Config', icon: 'settings', tooltip: 'Download current configuration ', onclick: () => { return onClickDownLoadConfig() } },
						{ name: 'Duplicate', icon: "file", tooltip: 'duplicate configuration', onclick: () => { return handleCopy() } },] :
						[
							{ name: 'View Configuration', icon: 'settings', tooltip: 'view current configuration ', onclick: () => { activeRow(rowindex, true) } },
							{ name: 'Download Config', icon: 'settings', tooltip: 'Download current configuration ', onclick: () => { return onClickDownLoadConfig() } },
							{ name: 'Duplicate', icon: "file", tooltip: 'duplicate configuration', onclick: () => { return handleCopy() } },]
					} visible={show} />
				)}
			</div>
		</div> : <>
			<div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "end" }}>
				<div style={{ width: '19vw', display: 'flex', position: 'relative', alignItems: 'center', justifyContent: 'end', gap: '4px' }}>
					{(configJson.WEB_ONLY===0)&&<Button onClick={() => setSyncSelect(true)} spacing='none' shape='localButton' stroke='sync' color='sync' fill='transparent' disabled={(navigator.onLine) ? false : true}>Sync</Button>}
					<div style={{ width: '30px', height: '31px', border: '1px solid #3399D5', display: 'flex', justifyContent: 'center', alignItems: 'center',cursor:"pointer" }} onClick={handler}>
						<Icons stroke='menu' iconName={'more-vertical'} size={'15px'} />
					</div>
				</div>
				<div onMouseLeave={lostfocus}>
					{show && selectedRow == rowindex && (
						<Menu hide={setShow} spacing='local' shape='local' fontSize='local' effects='outerShadow' fill='local' data={(navigator.onLine) ? (configJson.WEB_ONLY===0)?[{ name: 'Sync to remote', icon: 'user', tooltip: 'sync configuration to remote', onclick: () => { setSyncSelect(true) } },
						{ name: 'View Configuration', icon: 'settings', tooltip: 'view current configuration', onclick: () => { activeRow(rowindex, true) } },
						{ name: 'Download Config', icon: 'settings', tooltip: 'Download current configuration ', onclick: () => { return onClickDownLoadConfig() } },
						{ name: 'Freeze', icon: "file", tooltip: 'freeze configuration', onclick: () => { return onClickFreeze(rowindex) } },
						{ name: 'Edit Details', icon: "file", tooltip: 'edit configuration', onclick: () => { return handleEdit() } },
						{ name: 'Duplicate', icon: "file", tooltip: 'duplicate configuration', onclick: () => { return handleCopy() } },
						{ name: 'Delete', icon: "delete", tooltip: 'delete current configuration', onclick: () => { setactive(true) } }]:[
						{ name: 'View Configuration', icon: 'settings', tooltip: 'view current configuration', onclick: () => { activeRow(rowindex, true) } },
						{ name: 'Download Config', icon: 'settings', tooltip: 'Download current configuration ', onclick: () => { return onClickDownLoadConfig() } },
						{ name: 'Freeze', icon: "file", tooltip: 'freeze configuration', onclick: () => { return onClickFreeze(rowindex) } },
						{ name: 'Edit Details', icon: "file", tooltip: 'edit configuration', onclick: () => { return handleEdit() } },
						{ name: 'Duplicate', icon: "file", tooltip: 'duplicate configuration', onclick: () => { return handleCopy() } },
						{ name: 'Delete', icon: "delete", tooltip: 'delete current configuration', onclick: () => { setactive(true) } }] :
							[
								{ name: 'View Configuration', icon: 'settings', tooltip: 'view current configuration', onclick: () => { activeRow(rowindex, true) } },
								{ name: 'Download Config', icon: 'settings', tooltip: 'Download current configuration ', onclick: () => { return onClickDownLoadConfig() } },
								{ name: 'Edit Details', icon: "file", tooltip: 'edit configuration', onclick: () => { return handleEdit() } },
								{ name: 'Duplicate', icon: "file", tooltip: 'duplicate configuration', onclick: () => { return handleCopy() } },
								{ name: 'Delete', icon: "delete", tooltip: 'delete current configuration', onclick: () => { setactive(true) } }]
						} visible={show} />
					)}
				</div>
			</div>
		</>))
		)
	};

	//The status column with local/remote button
	const col2 = (coldata: any) => {
		return (coldata.map((col: string, row: number) => (
			<Button shape="localbutton" spacing="localbutton" fill="transparent" stroke='status'><Icons iconName={'monitor'} size={'14px'} stroke='status'></Icons><p style={{ fontWeight: "500", fontSize: "12px", color: '#56BA64', fontFamily: "Inter" }} onClick={() => activeRow(row, true)}>Local</p></Button>
		)))
	}

	const col6 = (coldata: any) => {
		return (coldata.map((col: any) => (
			<p style={{ padding: '0px', margin: '0px', cursor: 'pointer' }}>{col}</p>))
		)
	};

	//First column with custom part number
	const col3 = (coldata: any) => {
		var index = 0
		return (coldata.map((col: any, res: any) => (
			(data[res].custom_part_number) ? <div style={{ width: "15vw",cursor:"pointer" }} onClick={() => activeRow(res, true)}>
				<div style={{ padding: '0px', margin: '0px', display: "flex", gap: "5px", width: "100%", alignItems: "center" }} ><p style={{ wordWrap: "break-word", width: "13.5vw", padding: "0px", margin: "0px" }}>{col}</p><img src='/logo/Freeze Icon.svg' alt="Freeze" height={15} width={15} color='#3399D5'></img></div>
				<p style={{ padding: '0px', margin: '0px', color: "#8E8D8A", fontSize: '0.8vw' }}>Part Number {data[res].custom_part_number}</p>
			</div> :
				<>
					<p style={{ padding: '0px', margin: '0px', width: "13.5vw", wordWrap: "break-word",cursor:"pointer" }} onClick={() => activeRow(res, true)}>{col}</p>
				</>

		))
		)
	};
	console.log(AppData.currentConfig.frozen)
	const colum0 = col6(platform.map(x => (x)))
	const colum1 = col2(data.map(x => (x[1])))
	const rowLengthArray = Array.from({ length: data.length }, (v, i) => i)
	const colum3 = col3(name.map(x => (x)))
	const colum4 = col6(variant.map(x => (x)))
	const colum6 = col6(modified_date.map(x => (convertUTCDateToLocalDate(new Date(x + 'Z')).toLocaleString())))
	const colum8 = col1(data, rowLengthArray)

	const col5 = (coldata: any, data: any, rowindex: any) => {
		return (rowindex.map((row: any) => (data[row] == 'local' ? coldata = localdata : coldata = remotedata))
		)
	};

	const displayData = colum0.map(function (e: any, i: any) {
		return (configJson.WEB_ONLY===0)?[colum3[i], colum1[i], colum4[i], colum6[i], colum8[i]]:[colum3[i], colum4[i], colum6[i], colum8[i]];
	});

	const onClickFreeze = async (rowSelected: any) => {
		setWait(true)
		setloader(true)
		var config: any
		config = AppData.userConfig.data.local[rowSelected]
		try {
			await generateStates(AppData.server)
			await getBlockDiagramData(config, AppData.server)
			var result = await getFunctionState(["PPM"], 'local', AppData.blockDiagramData[0]._id)
			AppData.ppmValue = result[0].function_state
			setloader(false)
			setWait(false)
			handleFreeze()
		}
		catch {
			setloader(false)
		}
	}
	const activeRow = (rowSelected: any, viewSelected: boolean) => {
		onSelect(false)
		setVisible(false)
		//After row is selected the configuration initialize,generate States and blockdiagram API will be called.
		if (viewSelected)
			setloader(true)
		AppData.componentList = []
		AppData.labelList = []
		AppData.blockDiagramData = []
		setSelectedrow(rowSelected)
		AppData.freezeFormData = { "freeze": [] }
		AppData.server = 'local'
		onSelectRow(AppData.userConfig.data.local[rowSelected])
		onSelectSetMessage(false,'')
		console.log('ROW', AppData.userConfig.data.local[rowSelected], rowSelected)
		var config: any
		config = AppData.userConfig.data.local[rowSelected]
		try {

			initializeConfig(config, AppData.server).then((item) => {
				if (viewSelected) {
					generateStates(AppData.server).then((val) => {
						try {
							getBlockDiagramData(config, AppData.server).then((response) => {
								try {
									AppData.componentList = []
									AppData.blockDiagramData[0].place.map((placeData: any) => {
										if ((placeData.type === 'Driver') && (placeData.clickable !== 'NO')) {
											AppData.componentList.push(placeData.name)
											AppData.labelList.push(placeData.label)
										}
									})
									SummaryView().then(res => {
										console.log('navigation')
										onclickConfig(true)
										setloader(false)
									})
								}
								catch {
									if (viewSelected) {
										setloader(false)
									}
								}
							})
						}
						catch {
							if (viewSelected) {
								setloader(false)
							}
						}
					})
				}
			})
		}
		catch {
			if (viewSelected) {
				setloader(false)
			}
		}
		AppData.currentConfig = config
		console.log('initialized', AppData.currentConfig, config)
	}


	if (syncSelect && (selectedRow || selectedRow === 0)) {
		onSelect(false)
		syncConfig('local', data[selectedRow], onSync, recentData).then((res) => { return setMessageComp(true, `${AppData.currentConfig.name} configuration was synced to ${AppData.server == 'remote' ? "local" : 'remote'}`), setSyncSelect(false); })
	}
	if (viewAddendum && (selectedRow || selectedRow === 0)) {
		OnclickViewAddendum();
		setViewAddendum(false)
	}
	if (edit) {
		formData.edit[0].placeHolder = data[selectedRow].name
		formData.edit[1].placeHolder = data[selectedRow].description

		formData.edit[0].content = data[selectedRow].name
		formData.edit[1].content = data[selectedRow].description

	}
	else {
		formData.duplicate[0].content = ''
		formData.duplicate[1].content = ''
	}
	const deleteClick = () => {
		onClickDelete()
		setMessageComp(true, `${AppData.currentConfig.name} configuration was deleted successfully`)
	}
	return (
		<ThemeProvider theme={PrimaryTheme}>
			{pdfhandle && <PopupPdf shape={"hug"} visible={pdfhandle} spacing={"none"} fill={"popupform"} stroke={""} html={AppData.html} onClickDownload={() => downloadAddendum()} onClickCancle={() => { hide(false) }}></PopupPdf>}
			{loader && <LoadingSpinner shape={"medium"} stroke={"normal"} fill={"none"} innerfill={"loader"} speed={"1"} text={download ? "Downloading PDF" : (wait) ? "Loading Freeze Data..." : "Configuration opening in progress"} position={{ top: '40vh', left: '45vw' }} type={'circular'} disabled={false} ></LoadingSpinner>}
			<Message visible={visible} shape="config" font="config" position="bottom-right" text={message} hide={setVisible}></Message>
			<DialogBox shape="dialog" Buttoncontent={["Cancel", "Delete"]} position='middle-center' visible={active} header={'Delete Configuration'} text={"Are you sure you want to delete the configuration ? This process can't be undone."} spacing="dialog" fill="dialog" font="dialog" hide={setactive} OnclickYes={deleteClick}></DialogBox>
			<DataGrid Scroll={displayData.length>5?true:false} onfocuslost={lostfocus} data={data} displayData={displayData} columns={Columns} shape={shape} fill={fill} stroke={stroke} onClick={activeRow} />
		</ThemeProvider>
	);
}
export { ConfigLocalTable, convertUTCDateToLocalDate }
